import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimezoneDropdown from "./TimezoneDropdown";
import { useNavigate } from 'react-router-dom';

function Header({ setPeriod, selectedDate, setSelectedDate, setTimezone, selectedTimezone, setLive }) {
    const navigate = useNavigate();

    const handleNavigation = (path, period) => {
        setPeriod(period);
        navigate(path);
    };

    return (
        <header>
            <nav className="nav-menu">
                {/*<img
                    src=""
                    alt="Logo" className="logo"/>*/}
                <div className="logo-text">SCORETABLE.LIVE</div>
                <div className="nav-menu-items">
                    <button onClick={() => handleNavigation('/', 'now')}>Now</button>
                    <button onClick={() => handleNavigation('/', 'yesterday')}>Yesterday</button>
                    <button onClick={() => handleNavigation('/', 'today')}>Today</button>
                    <button onClick={() => handleNavigation('/', 'tomorrow')}>Tomorrow</button>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setLive(false);
                            setSelectedDate(date);
                            navigate('/');
                        }}
                        dateFormat="yyyy-MM-dd"
                        className="date-picker"
                    />
                    <TimezoneDropdown
                        selectedTimezone={selectedTimezone}
                        onTimezoneChange={setTimezone}
                    />
                </div>
            </nav>
        </header>
    );
}

export default Header;
