import React, { useEffect, useState, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import MatchRow from './MatchRow';
import Drawer from './Drawer';
import StarComponent, {getStarredLeagues} from './StarComponent';
import {getStarredMatches} from "./MatchStarComponent";
import SearchComponent from './SearchComponent';
import PrevRoundComponent from './PrevRoundComponent';
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as emptyStar} from "@fortawesome/free-regular-svg-icons";

function MatchList({ selectedDate, live, timezone }) {
    const [matches, setMatches] = useState([]);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const isFetching = useRef(false);
    const topLeagueIds = [1,4,2,3,848,78,39,140,135,61,144,88,94,203,207,119,103,271,235,106,345,210,283,286,373,218,357,113,197,179,79,89,141,136,95,120,104,208,40,41,42,358,114,98,99,100,292,293,71,72,262,253,180,66,65,143,45,48,199,529,137,547,817,81,147,550,209,115,97,108,105,212,555,556,90,543,96,519,206,551,654,663,727,557,871,1021,21,803,62,204,63,7,128,129,131,299,15,480,535,268,270,242,342,807,19,24,22,6,9,531,16,17,200,239,250,130,281,252,18,20,5,265,808,31,32,33,34,29,30,12,35,767,810,483,536,713,1043,960];

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState({ home: '', away: '' });
    const [roundAndSeason, setRoundAndSeason] = useState({ leagueId: '', round: '', season: '' });
    const [starredLeagues, setStarredLeagues] = useState(getStarredLeagues());
    const [starredMatchesCookie, setStarredMatchesCookie] = useState(getStarredMatches());
    const [starredMatches, setStarredMatches] = useState([]);

    const closeDrawer = () => setIsDrawerOpen(false);

    useEffect(() => {
        if (isFetching.current) {
            return;
        }

        isFetching.current = true;

        // Simulate fetching matches for the selected date
        const fetchMatches = (date, live=false) => {
            let query;
            if (live) {
                console.log(`Fetching live matches`);
                query = `?live=all&timezone=${timezone}`
            } else {
                const formattedDate = moment(date).tz(timezone).format('YYYY-MM-DD');
                console.log(`Fetching matches for ${formattedDate}`);
                query = `?date=${formattedDate}&timezone=${timezone}`;
            }

            axios.get(`https://v3.football.api-sports.io/fixtures${query}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
              .then(response => {
                  if (response && response.data) {
                      //console.log(response.data.response);

                      if (response.data.response && response.data.response.length) {
                          const sortedMatches = response.data.response.sort((a, b) => {
                              return new Date(a.fixture.date) - new Date(b.fixture.date);
                          });
                          setMatches(sortedMatches);
                          setFilteredMatches(sortedMatches);
                      }
                  }

                  isFetching.current = false;
              })
              .catch(error => {
                  console.error('Error fetching matches:', error);
                  isFetching.current = false;
              });
        };

        fetchMatches(selectedDate, live);
    }, [selectedDate, live, timezone]);

    const updateStarredLeagues = () => {
        setStarredLeagues(getStarredLeagues());
    };

    const updateStarredMatches = () => {
        setStarredMatchesCookie(getStarredMatches());
    };

    useEffect(() => {
        // Update starredMatches whenever matches or starredMatchesCookie changes
        const newStarredMatches = matches.filter(match => 
            starredMatchesCookie.includes(match.fixture.id)
        );
        setStarredMatches(newStarredMatches);
    }, [matches, starredMatchesCookie]);

    // Group matches by league.id
    const groupedMatches = filteredMatches.reduce((groups, match) => {
        const leagueId = match.league.id;
        if (!groups[leagueId]) {
            groups[leagueId] = [];
        }
        groups[leagueId].push(match);
        return groups;
    }, {});

    const sortedKeys = [
        ...starredLeagues.filter(id => id in groupedMatches || id.toString() in groupedMatches),
        ...topLeagueIds.filter(id => (id in groupedMatches || id.toString() in groupedMatches) && !starredLeagues.includes(id)),
        ...Object.keys(groupedMatches).filter(id => !topLeagueIds.includes(id.toString()) && !topLeagueIds.includes(parseInt(id)) && !starredLeagues.includes(parseInt(id))),
    ];

    // Search handler
    const handleSearch = (searchTerm) => {
        if (!searchTerm) {
            setFilteredMatches(matches);
            return;
        }

        const filtered = matches.filter(match =>
            match.teams.home.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            match.teams.away.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            match.league.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            match.league.country.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredMatches(filtered);
    };

    return (
        <div className="match-list">
            <SearchComponent onSearch={handleSearch} />
            <div className="match-header">
                <span>Time</span>
                <span className="team-name">Home</span>
                <span>Score</span>
                <span className="team-name">Away</span>
                <span>HT Score</span>
                <span>Status</span>
            </div>
            {starredMatches.length > 0 && (
                <div key="favourite-matches" className="league-section">
                    <div className="league-header">
                        <div>
                            <span className="starIcon flag-icon">
                                <FontAwesomeIcon icon={emptyStar}/>
                            </span>
                            <span
                                className="league-name">Favourite Matches
                                </span>
                        </div>
                    </div>
                    {starredMatches.map((match) => (
                        <MatchRow key={match.fixture.id}
                                  match={match}
                                  dateFormat="HH:mm"
                                  h2h={false}
                                  timezone={timezone}
                                  setIsDrawerOpen={setIsDrawerOpen}
                                  setSelectedTeams={setSelectedTeams}
                                  onStarUpdate={updateStarredMatches}
                        />
                    ))}
                </div>
            )}
            {sortedKeys.map((leagueId) => {
                const hasNonStarredMatches = groupedMatches[leagueId].some(match => !getStarredMatches().includes(match.fixture.id));
                if (hasNonStarredMatches) {
                    return (
                        <div key={leagueId} className="league-section">
                            <div className="league-header">
                                <div className="league-header-content">
                                    <div>
                                        <span>
                                            {
                                                (groupedMatches[leagueId][0].league.flag || groupedMatches[leagueId][0].league.logo) && (<img
                                                    src={(groupedMatches[leagueId][0].league.flag || groupedMatches[leagueId][0].league.logo).replace('media.api-sports.io', 'media.scoretable.live')}
                                                    alt={groupedMatches[leagueId][0].league.country} className="flag-icon"/>)
                                            }
                                        </span>
                                        <span
                                            className="league-name">{groupedMatches[leagueId][0].league.country} {groupedMatches[leagueId][0].league.name}
                                        </span>
                                    </div>
                                    <div className="league-actions">
                                        {
                                            groupedMatches[leagueId][0].league.round && groupedMatches[leagueId][0].league.season && (
                                                <PrevRoundComponent
                                                    leagueId={leagueId}
                                                    round={groupedMatches[leagueId][0].league.round}
                                                    season={groupedMatches[leagueId][0].league.season}
                                                    setIsDrawerOpen={setIsDrawerOpen}
                                                    setSelectedTeams={setSelectedTeams}
                                                    setRoundAndSeason={setRoundAndSeason}
                                                />)
                                        }
                                        <StarComponent
                                            leagueId={leagueId}
                                            onStarUpdate={updateStarredLeagues}
                                        />
                                    </div>
                                </div>
                            </div>
                            {groupedMatches[leagueId].map((match) => {
                                if (!getStarredMatches().includes(match.fixture.id)) {
                                    return (
                                        <MatchRow key={match.fixture.id}
                                                  match={match}
                                                  dateFormat="HH:mm"
                                                  h2h={false}
                                                  timezone={timezone}
                                                  setIsDrawerOpen={setIsDrawerOpen}
                                                  setSelectedTeams={setSelectedTeams}
                                                  onStarUpdate={updateStarredMatches}
                                                />
                                    );
                                }
                                return null;
                            })}
                        </div>
                    );
                }
                return null;
            })}
            <Drawer
                isDrawerOpen={isDrawerOpen}
                selectedTeams={selectedTeams}
                roundAndSeason={roundAndSeason}
                closeDrawer={closeDrawer}
            />
        </div>
    );
}

export default MatchList;
