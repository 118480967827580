import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function SearchComponent({ onSearch }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        // Clear the previous timeout if the user is still typing
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        // Set a new timeout to execute the search after a delay
        const timeout = setTimeout(() => {
            onSearch(value);
        }, 300); // Delay of 300ms

        setTypingTimeout(timeout);
    };

    return (
        <div className="search-matches">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Search matches..."
            />
        </div>
    );
}

export default SearchComponent;
