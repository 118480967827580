import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

const PrevRoundComponent = ({ leagueId, round, season, setIsDrawerOpen, setSelectedTeams, setRoundAndSeason }) => {
    leagueId = parseInt(leagueId, 10);

    function showPrevRoundMatches() {
        setIsDrawerOpen(false);
        setSelectedTeams({ home: '', away: '' });
        setRoundAndSeason({ leagueId, round, season });
        setIsDrawerOpen(true);
    }

    return (
        <span className="starIcon">
            <FontAwesomeIcon icon={faHistory} onClick={showPrevRoundMatches} title="Previous round"/>
        </span>
    );
};

export default PrevRoundComponent;
