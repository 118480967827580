import React from 'react';
import H2H from "./H2H";

function Drawer({ isDrawerOpen, selectedTeams, roundAndSeason, closeDrawer }) {
    return (
        <>
            {isDrawerOpen && ((selectedTeams.home && selectedTeams.away) || (roundAndSeason.leagueId && roundAndSeason.round && roundAndSeason.season)) && (
                <H2H isOpen={isDrawerOpen}
                     onClose={closeDrawer}
                     homeTeam={selectedTeams.home}
                     awayTeam={selectedTeams.away}
                     leagueId={roundAndSeason.leagueId}
                     round={roundAndSeason.round}
                     season={roundAndSeason.season}
                />
            )}
        </>
    );
}

export default Drawer;
