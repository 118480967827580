import React, { useEffect, useState } from 'react';
import axios from "axios";
import MatchRow from "./MatchRow";
import moment from 'moment';

function H2H({ isOpen, onClose, homeTeam, awayTeam, leagueId, round, season }) {
    const [matches, setMatches] = useState([]);
    const drawerDesc = homeTeam && awayTeam ? `Head To Head Matches` : `Previous Round Matches`;

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        const toDate = moment().add(1,'days').format('YYYY-MM-DD');
        // Simulate fetching matches for the selected date
        const fetchH2H = (homeTeam, awayTeam) => {
            const statuses = 'FT-AET-PEN-NS'
            axios.get(`https://v3.football.api-sports.io/fixtures/headtohead?h2h=${homeTeam}-${awayTeam}&from=2000-01-01&to=${toDate}&status=${statuses}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response && response.data.response.length) {
                        //console.log(response.data.response);

                        setMatches(response.data.response.sort((a, b) => {
                            return new Date(b.fixture.date) - new Date(a.fixture.date);
                        }));
                    }
                })
                .catch(error => {
                    console.error('Error fetching matches:', error);
                });
        };

        const getPreviousRoundMatches = (round) => {
            axios.get(`https://v3.football.api-sports.io/fixtures?league=${leagueId}&round=${round}&season=${season}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response && response.data.response.length) {
                        //console.log(response.data.response);

                        setMatches(response.data.response.sort((a, b) => {
                            return new Date(b.fixture.date) - new Date(a.fixture.date);
                        }));
                    }
                })
                .catch(error => {
                    console.error('Error fetching prev round matches:', error);
                });
        }

        const getPreviousRoundForLeague = () => {
            axios.get(`https://v3.football.api-sports.io/fixtures/rounds?league=${leagueId}&season=${season}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response && response.data.response.length) {
                        const roundIndex = response.data.response.indexOf(round);

                        if (roundIndex - 1 >= 0) {
                            getPreviousRoundMatches(response.data.response[roundIndex - 1]);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching rounds:', error);
                });
        }

        if (homeTeam && awayTeam) {
            fetchH2H(homeTeam, awayTeam);
        } else if (leagueId && round && season) {
            getPreviousRoundForLeague();
        }

    }, [homeTeam, awayTeam, leagueId, round, season]);

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div className={`drawer ${isOpen ? 'open' : ''}`}>
                <div className="match-list">
                    <div className="match-header drawer-desc">
                        <span>{drawerDesc}</span>
                    </div>
                    <div className="match-header">
                        <span>Date</span>
                        <span className="team-name">Home</span>
                        <span>Score</span>
                        <span className="team-name">Away</span>
                        <span>HT Score</span>
                        <span>Status</span>
                    </div>
                    {matches.map((match) => (
                        <MatchRow key={match.fixture.id}
                                  match={match}
                                  dateFormat="YYYY-MM-DD"
                                  h2h={true}/>
                    ))}
                </div>
            </div>
            {isOpen && <div className="overlay" onClick={onClose}></div>}
        </>
    );
}

export default H2H;