import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Header from './components/Header';
import './css/base.css';
import './css/header.css';
import './css/match-list.css';
import './css/drawer.css';
import './css/timezone-dropdown.css';
import './css/star-component.css';
import './css/search.css';
import './css/match-details.css';
import './css/policy-pages.css';
import './css/media-queries.css';
import MatchList from './components/MatchList';
import Footer from "./components/Footer";
import moment from "moment";
import 'moment-timezone';
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import CookieBanner from "./components/CookieBanner";

function App() {
    const [period, setPeriod] = useState('today');
    const [live, setLive] = useState(false);
    const [timezone, setTimezone] = useState(() => getTimezoneFromCookie());
    const [selectedDate, setSelectedDate] = useState(moment().tz(timezone).toDate());

    useEffect(() => {
        // Function to determine the date based on the period
        const determineDate = (period) => {
            const today = new Date();
            switch (period) {
                case 'yesterday':
                    return new Date(today.setDate(today.getDate() - 1));
                case 'today':
                    return today;
                case 'tomorrow':
                    return new Date(today.setDate(today.getDate() + 1));
                default:
                    return null;
            }
        };

        if (period === 'now') {
            setLive(true);
        } else {
            const dateToFetch = determineDate(period);

            if (dateToFetch) {
                setLive(false);
                setSelectedDate(dateToFetch);
            }
        }

        setTimezoneCookie(timezone);
    }, [period, timezone]);

    return (
        <div className="App">
            <Header setPeriod={setPeriod}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    setLive={setLive}
                    setTimezone={setTimezone}
                    selectedTimezone={timezone}
            />

            <Routes>
                <Route path="/" element={
                    <MatchList
                        selectedDate={selectedDate}
                        live={live}
                        timezone={timezone}
                    />
                } />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="cookie-policy" element={<CookiePolicy />} />
            </Routes>
            <Footer />
            <CookieBanner />
        </div>
    );
}

function setTimezoneCookie(timezone) {
    Cookies.set('timezone', timezone, { expires: 365 });
}

function getTimezoneFromCookie() {
    return Cookies.get('timezone') || moment.tz.guess(); // Default to the guessed timezone if not set
}


export default App;